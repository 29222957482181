@import './../styles.less';

@primary: #f05b47;
@lightBackground: #fbfbfb;

.admin-portal {
    .sidebar-mini {
        display: flex;
        > .wrapper {
            flex-grow: 1;
        }
        max-width: 100vw;
    }

    .main-footer {
        height: 100px;
    }

    app-footer .main-footer {
        display: block;
        max-height: 50px;
    }
}
