@import (reference) './../styles.less';

@primary: #e97c17;
@btnPrimaryColor: lighten(@primary, 20%);
@btnPrimaryColorActive: @primary;

.student-portal {
    @secondary: #cf3d2e;
    @textcolor: rgb(0, 0, 0);
    @navPrimary: #cf3d2e;
    @accent: #d21e0f;
    @anchor: #3f51b5;
    @grey: #d9d9d9;
    @grey2: #565656;
    @errorcolor: #b71c1c;
    @success: #1ec93c;
    @light-orange: #e7ad4b;
    @light-blue: #cdebeb;

    .main-header {
         box-shadow: 0 0 4px 0 rgba(255, 96, 33, 0.14), 0 4px 8px 0 #c86000;
         .navbar {
             background-color: @primary !important;
        }
        li.user-header {
            background-color: @primary !important;
        }
    }

    .main-footer {
        background-color: @primary !important;
    }

    .btn-primary,
    .btn-primary:active,
    .btn-primary:visited,
    .btn-primary:focus {
        background-color: @btnPrimaryColor;
        border-color: @btnPrimaryColor;
    }

    .btn-primary.active,
    .btn-primary.active:hover,
    .btn-primary.focus.active,
    .btn-primary.focus.active:hover,
    .btn-primary:active:focus,
    .btn-primary:active:hover,
    .btn-primary:hover {
        background-color: @btnPrimaryColorActive;
        border-color: @btnPrimaryColorActive;
        outline: none;
    }

    @font-face {
        font-family: 'ITCAvantGardeOpentype';
        src: url('../assets/fonts/ITC Avant Garde Opentype/ITCAvantGardeStd-Bk.otf') format('opentype');
    }

    *:not(i) {
        font-family: 'ITCAvantGardeOpentype', Arial, Helvetica, FontAwesome, sans-serif !important;
    }

    tr.archived {
        background-color: rgb(255, 192, 203) !important;
    }
    tr.archived > td {
        background-color: rgb(255, 192, 203) !important;
    }

    .content-wrapper {
        background-image: url('../assets/stars-2.png');
        background-color: @light-orange;
        min-height: 89.2vh;
        display: flex;
        flex-direction: column;
        > div {
            flex-grow: 1;
            display: flex;
            > :not(ng-progress, router-outlet) {
                flex-grow: 1;
            }
            > ng-component {
                display: flex;
                > * {
                    flex-grow: 1;
                }
            }
        }
    }

    .lesson-container {
        display: flex;
        justify-content: center;
        max-width: 1250px;
        margin: auto;
    }

    .btn-red {
        background-color: @accent;
        color: white;
    }

    .btn-blue {
        background-color: @anchor;
        color: white;
    }

    .btn-green {
        background-color: @success;
        color: white;
    }

    .btn-orange {
        background-color: @primary;
        color: white;
    }

    .vertical-center-text {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
    }

    .justify-center {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .space-between {
        display: flex;
        justify-content: space-between;
    }

    .word-block-container-shrink {
        flex-shrink: 1;
        min-width: 150px;
    }

    .block-container {
        background-color: white;
        color: black;
        height: 80px;
        font-size: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @light-blue;

        &.clickable {
            cursor: pointer;

            &:hover {
                background-color: lightgrey;
            }
        }
    }

    .chart {
        display: flex;
        flex-wrap: wrap;
        width: 600px;
        border-radius: 10px;
        justify-content: center;
    }

    .chart-row {
        display: flex;
        flex-direction: row;
        width: 600px;
        justify-content: center;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .cursor-not-allowed {
        cursor: not-allowed;
    }

    .instructions-text {
        color: white;
    }
}

.home-button {
	align-self: flex-start;
	margin-right: 10px;
}
